.l-footer {
  color: $white;

  li {
    padding-top:0.8rem;
  }


  .cms-page-link,
  .custom-page-link,
  .account-list a{
    color: $white;
    font-weight: 300;
    &:hover{
      color: $green;

    }
  }

  #footer_store_info {
    line-height:2rem;
  }
}
.footer-container{
  margin-top: $footer-container-margin-top;
  max-width:1030px;
  margin-left: auto;
  margin-right: auto;
  .social-share-btn {
    background-color:transparent;
    background-size: 65%;
    padding:0;
    margin:0;
    &.facebook {
      margin-left:-10px;
    }
    &.linkedin {
      background-size:50%;
    }
  }
}

.footer__title{
  color:$green;
  &:visited,&:hover,&:active {
    color: $green;
  }
  font-family:$font-family-aglet;
  font-weight:500;
  display: block;
  font-size:0.9rem;
  margin-bottom: $spacer/2;
  &--mobile{
    display: none;
    margin-bottom: 0;
    padding: $spacer 0;
    border-bottom: 1px solid #6C6D6B;
    

    &:after{
      content:"\e145";
      color:$white;
      font-family: 'Material Icons';
      font-feature-settings: 'liga' 1;
      position: absolute;
      right: $grid-gutter-width/2;
      top: 10px;
      transition: .3s transform ease-in-out;
      font-size: $font-size-base *1.5;
    }
    &[aria-expanded="true"]{
      border: 0;
      font-weight:bold;
    }
    &[data-toggle="collapse"][aria-expanded="true"]:after {
      
      content:"\e15b";
    }
  }
}

.footer-metodi {
  font-size:2.5rem;
}

.copyright-link {
  color:#afadad;
}

@include media-breakpoint-mobile {
  .footer__title{
    &--desktop{

      display: none;
    }
    &--mobile{
      display: block;
    }
  }
}
.footer__copyright{
  text-align: center;
  font-size: $font-size-sm;
}
.header__nav{
  align-items: center;
}
.header-nav__nav2{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-mobile{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-mobile__user-cart{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__top{
  display: flex;
  justify-content: space-between;
  position: static;
}

