.layout_layout4 {
    .ets_mm_megamenu_content {
        border-top:0!important;
    }

    .mm_menu_content_title {
        text-transform:none;
        font-weight:400;
    }
    .ets_mm_block_content .has-sub > a:after {
        content:unset;
    }
    .column_size_4 {
        width: auto;
    }

    .column_size_2:not(:last-child) {
        border-left: 1px solid #979797!important;
    }
    
    a.milani-card-link {
        position:relative;
        color: $midgrey!important;
        font-weight:600;
        line-height:1!important;
        font-size:0.7rem;
        &:hover {
            color: $green!important;
        }
    }
}

.layout_layout4.ets_mm_megamenu .mm_columns_ul {
    margin-top:0!important;
    border:0;
}

.layout_layout4:not(.ybc_vertical_menu) .mm_menus_ul .mm_menus_li > a:before, .layout_layout4:not(.ybc_vertical_menu) .mm_menus_ul .mm_menus_li:nth-child(2) > a::after {
    content: none!important;
}

body {
    .ets_mm_megamenu {
        &::before {
            content: none!important;
        }
}
}


@media (min-width: 768px) and (max-width: 1200px){
    .layout_layout4 {

        .mm_menu_content_title {
            padding-right:0;
            padding-left:0;
            font-size:15px;
        }

    }

    

}

@media (min-width: 768px) {
    .layout_layout4 .mm_menus_ul .mm_menus_li {
        padding: 10px 0 0 0!important;
    }
    .layout_layout4 .ets_mm_megamenu_content {
        padding-top:0!important;
    }
}

@media (min-width: 992px){
    .mm_columns_ul {
        width: 140%!important;
    }

    .ets_mm_megamenu .mm_columns_ul {
        justify-content: space-around;
        display: flex;
        justify-content: space-evenly;
    }




    .milani-card {
        width: 100px;
    }


    .ets_mm_block_content {
        a {

            font-size: 15px;
            font-weight: 300;
            line-height: 2.3!important;
        }
    }


    .column_size_2:not(:last-child) {
        padding-left:10px!important;
    }
}

@media (min-width: 1050px) {
    .layout_layout4 .mm_menus_ul .mm_menus_li {
        padding: 10px 1px 0 1px!important;
    }

    
 }

 @media (min-width:990px){

    .menu-azienda > .mm_columns_ul  {
        width: 260px!important;
        left:230px!important;
    }
 }

@media (min-width: 1100px){
    .mm_columns_ul {
        width: 155%!important;
    }

    .milani-card {
        width: 120px;
    }
}

@media (min-width: 1200px){
    .mm_columns_ul {
        width: 140%!important;
    }
    .menu-azienda > .mm_columns_ul  {
        width: 260px!important;
        left:329px!important;
    }
}

@media (min-width: 1260px) {
    .milani-card {
        width: 140px;
    }
    .mm_columns_ul {
        width: 136%!important;
        left:20px!important;
    }
    .menu-azienda > .mm_columns_ul  {
        width: 260px!important;
        left:380px!important;
    }
    .layout_layout4 .mm_menus_ul .mm_menus_li {
        padding: 10px 10px 0 10px!important;
    }
 }

@media (min-width: 1350px) {
    .layout_layout4 .mm_menus_ul .mm_menus_li {
        padding: 10px 15px 0 15px!important;
    }
    .mm_columns_ul {
        /*width: 136%!important;*/
    }
    .menu-azienda > .mm_columns_ul  {
        left:317px!important;
    }
 }

 @media (min-width: 1400px){


    
    .ets_mm_megamenu .mm_columns_ul {
        justify-content: space-evenly;
    }
    
    .milani-card {
        width: 180px;
    }


    .ets_mm_block_content {
        a {

            font-size: 16px;
            font-weight: 300;
            line-height: 2.5!important;
        }
        .milani-card {
            width: 160px;
        }
    }

 }

 @media (max-width: 991px) {

    .fa-chevron-right {
        right: 25px;top: 15px;
    }

    .mm_menu_content_title_back {
        color:$green;
    }

     .header__menu {
         display:none;
     }
     .ets_mm_megamenu {
        margin-top:0!important;
        width:auto!important;
        margin-bottom:1rem;

        .mm_blocks_li {
            margin: 0;
            list-style:none;
            &:first-of-type {
                padding-top:10px;
            }
        }

        .mm_menus_ul {
            padding: 0 0 2rem 0;
            background-color:#fff;
        }
        .ets_mm_categories > li, .mm_menus_li {
            border-top:0;
            background-color:#fff;
            padding: 0 20px;

            &:hover, &:active {
                background-color:$midgrey;
                color:$green!important;
                a {
                    color:$green!important;
                    border:0;
                }
            }
            a {
                padding: 10px 0;
                border-bottom: 1px solid #D1D1D1;
                &:hover {
                    color:$green!important;
                }
            }

            &.mm_has_sub {
                position:relative;
            }
        }

        .ets_mm_categories > li {
            a {
                border:0;
            }
        }
        .mm_menu_content_title {
            text-transform:none;
            font-weight:400;
        }
     }
}