
.page-index {
    font-weight:300;

    #content-wrapper {
        .row {
            margin-top:0;
            margin-left:0;
            margin-right:0;
            @extend .milani-main;
        }
    }

    .featured-categories {
        .products-slider.categories-slider {
            width: 100%;
        }
    }

    .custom-block {
        img {
            max-width: 100%;
        }
        .col-12:not(.home-prodotto-evidenza) {
            padding-right:0;
            padding-left:0;
            flex: unset;
        }
    }

    //prodotti in evidenza
    .custom-block-6 > h2 {
        margin-left:84px;
    }
    .home-prodotto-evidenza {
        border-radius:.25rem;
        padding: 3rem 2rem;
        background-color:grey;
        color:$white;
        max-width: calc(50% - 5px);

        .title {
            font-family:$font-family-aglet;
            font-size:1.4rem;
            margin-bottom:1rem;
        }
        &:first-of-type {
            margin-right: 10px;
        }
    }

    //hero image box
    .homepage-hero {
        .container {
            padding-left: 84px;
            padding-right: 84px;
        }
        .homepage-hero-image {
            background-repeat: no-repeat;
            background-position: right;
        }
        @extend .milani-main;
        background-color:black;
        color:$white;
        margin-bottom:0;
        background-size: contain;
        background-position: right;
        background-repeat: no-repeat;
        .homepage-hero-text {
            width: 60%;
            padding-top:13rem;
            padding-bottom:13rem;
            line-height: 2.2;
        }
        .homepage-hero-title {
            font-family: $font-family-aglet;
            font-size:2.5rem;
            margin-bottom:1.5rem;
            text-transform: none;
        }
        .homepage-hero-subtitle {
            font-weight:300;
            font-size:1.4rem;
            text-transform: none;
        }

        .btn-green {
            margin-top:2.6rem;
            font-size:1.1rem;
            font-family: $font-family-aglet;
        }
    }


    //green box
    .green-box {
        background: $green url(../img/imilani/homepage-green-bg.png) no-repeat center center;
        background-size:cover;
        color:$white;
        font-weight:300;
        font-size:1.3rem;
        @extend .milani-main;
        .green-box-text {
            @extend .container;
            font-size:1.5rem;
            padding:7rem 8rem 3rem 8rem;
        }
        .green-box-link {
            @extend .container;
            padding:0 8rem 3rem 8rem;
            font-size:0.95rem;
            font-weight:600;
            i {
                padding-left:15px;
            }
        }
        a {
            color:$white;
        }
    }

    .row.custom-block {
        max-width: 1447px;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    //slider
    .slick__arrow-large .slick-arrow i {
        font-size:1.8rem;
        background-color:$greysix;
    }

    .rc {
        border-radius: .25rem;


        img {
            display: block;
            width: unset!important;
            height: 100%;
            object-fit: cover;
        }

        .display-1 {
            font-size: 1.2rem;
            line-height: 1.7;
            font-family:$font-family-aglet;
            text-transform:unset!important;
            color:$midgrey;

        }

        a {
            color:$midgrey;
        }

        p {
            font-size:0.94rem;
        }

        .caption-description {
            strong {
                font-family:$font-family-aglet;
                color:$midgrey;
                font-size:0.9rem;
                font-weight:600;
                text-transform:uppercase;
            }
        }

        .slider-caption {
            top:20%;
        }
    }
}

//Blocco newsletter
.row-newsletter {
    background-color: #f8f8f8;
    .container {
        padding-left: 84px;
        padding-right: 84px;
    }
    .label-newsletter {
        font-size: 1.3rem;
        font-weight: 600;
        color:#565656;
    }
    .form-control {
        color: $greysix;
        font-weight: 300;
        font-size: 0.8rem;
        &::-webkit-input-placeholder, &::placeholder, &::-ms-input-placeholder, &::-ms-input-placeholder {
            color: $greysix;
            font-weight: 300;
            font-size: 0.8rem;
        }
    }
    p.small {
        color: $greysix;
        font-weight: 300;
        font-size: 0.8rem;
    }
}

//Blocco featured categories
.featured-categories {
    background-color:#f8f8f8;
    .categories-slider {
        margin-top: 2em;
        overflow:visible;
        .product-miniature {
            width: 90%!important;
            max-width:unset!important;
            height:100%;
        }
    }
    .page-footer {
        max-width: 1447px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 84px;
        padding-right: 84px;
        margin-bottom: 0 !important;
        a {
            color: #75AF08;
            font-weight:500;
            &:hover {
                font-weight:600;
            }
        }
    }
}

// Blocco icone homepage
.homepage-icons {
    background-color:#f8f8f8;
    justify-content: center;
    margin-bottom:0!important;
    padding-top:4rem;
    padding-bottom:4rem;

    img {
        height:90px;
    }

    .title {
        margin: 1rem 0;
        font-size: 1.1rem;
    }
}

.homepage-esperienza-checklist {
    font-weight:600;
    font-size:1.1rem;
    li {
        padding-bottom:0.8rem;
        &:before {
            font-family: 'Material Icons';
            content: "\e876";
            color:$green;
            vertical-align:middle;
            padding-right:0.5rem;
            font-size:1.5rem;
        }
    }
}
@include media-breakpoint-mobile {
    //prodotti in evidenza
    .custom-block-6 > h2 {
        margin-left:0!important;
        margin-right:0!important;
    }

    .block_newsletter {
        padding:0;
    }
    .homepage-esperienza-checklist {
        li {
            line-height: 1.2;
        }
    }
    .page-index .home-prodotto-evidenza {
        max-width: unset;
    }
}

@media (min-width: 728px){
    .page-index {
        h2.greenbar-title {
            font-size: 1.8rem;
            max-width: 1000px;
            margin: 3rem 0 3rem 5rem;
        }
        //Blocco featured categories
        .featured-categories {
            background-color:#f8f8f8;
            .categories-slider {
                margin-top: 0;
            }
        }
    }
}

@media (max-width: 450px){
    .page-index {

        .col-6 {
            p {
                font-size:0.8rem;
            }
        }

        .col-12 {
            p {
                font-size:0.9rem;
            }
        }
        h2.greenbar-title {
            margin-bottom:1rem;
            font-size: 1rem;
            max-width: 1000px;
        }


        .rc {
            .slider-caption {
                top:  10%;
                left:15px;
                max-width: 80%;
            }
            p {
                font-size: 0.8rem;
            }

        }

    }
    .homepage-icons {
        .title {
            font-size: 0.95rem;
        }
    }
    .homepage-esperienza-checklist {
        font-size:1rem;
    }
}

@media (max-width: 850px){
    .page-index {
        #carousel {
            .slick-arrow {
                display:none!important;
            }
        }

        .rc {
            .slider-caption {
                top:  8%;
                left:25px;
            }
        }

        //green box
        .green-box {
            .green-box-text {
                font-size:1rem;
                padding:2rem 3rem 2rem 3rem;
            }
            .green-box-link {
                text-align:center!important;
                padding:0 3rem 2rem 3rem;
                font-size:0.8rem;
            }
        }
    }
}

@media (min-width: 851px){
    .page-index {
        .slick-dots {
            display:none!important;
        }
    }
}

@media (max-width:991px){

    .page-index {
        //prodotti in evidenza
        .home-prodotto-evidenza {
            margin-top:5px;

            &:first-of-type {
                margin-right: 0;
            }
        }


        //hero image box
        .homepage-hero-image {
            background-position-x: right;
            background-size: 30%;
        }

        .homepage-hero {

            .homepage-hero-text {
                width: 80%;
                padding-top:4rem;
                padding-bottom:4rem;
            }
            h2 {
                font-size:2rem;
            }
            h3 {
                font-size:1.1rem;
            }

            .btn-green {
                font-size:0.9rem;
            }
        }

        //Blocco featured categories
        .featured-categories {
            .categories-slider {
                .slick-prev {
                    left: -20px;
                }
                .slick-next {
                    right:-20px;
                }
            }

            .page-footer {
                padding-right:2rem;
            }

        }
    }




}

@media (max-width:576px){
    .row-newsletter {
        .input-group {
            .btn, .form-control {
                width: 100%;
            }
        }
    }
}



@media (max-width:450px){

    .page-index {

        //hero image box
        .homepage-hero {
            .container {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
            h2 {
                font-size:1.7rem;
            }
            h3 {
                font-size:1rem;
            }

            .btn-green {
                font-size:0.8rem;
            }
        }
    }


}
