
.page-authentication, .page-b2b-create-account, .page-order, .page-identity, .page-password, #module-b2bregistration-b2b {

    color:$greysix;

    .form-control {
        &[name="password"] {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }

      .btn-lightgrey {
        &[data-action="show-password"] {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          height:50px;
          font-family:$font-family-aglet;
        }
      }
    }

    .page-password {
      .page-footer {
        text-align:right;
      }
    }

  .page-authentication, .page-b2b-create-account, .page-password {
      #content-wrapper {
        padding:5rem 0 9rem 0;
    }
  }

  #module-b2bregistration-b2b {
    .page-wrapper--module-b2bregistration-business {
      &:not(.customer-main) {
        padding: 8rem 0 9rem 0;
      }
    }
  }

 .page-authentication, .page-b2b-create-account, .page-password, #module-b2bregistration-b2b {

    .no-account {
        font-size:0.9rem;
        a {
            font-weight:bold;
        }
    }

    .l-wrapper {
        @extend .l-wrapper-bgimage
    }
    .page-header {
        margin-bottom:3rem;
    }
    .l-header {
        margin-bottom:0;
    }
    .page-content {
      @extend .card;
      max-width: 724px;
      margin: 0 auto;
      padding-bottom: 2rem;
      padding-top: 4rem;
      a {
          color:$greysix;
      }
    }

    .greenbar-title, .page-footer, .page-content {
        max-width: 724px;
    }

    .greenbar-title {
        margin: 0 auto;
        color:$white;
    }



    .customer-main {
      .page-content--module-b2bregistration-b2b {
        max-width:unset!important;
      }
      &.page-wrapper--module-b2bregistration-b2b {
        .greenbar-title, .page-footer {
          max-width:unset!important;
        }
      }
    }

  }

  

  .login-form {
    .label, label {
      display:none;
    }
  }

  .register-form {
    p {
      max-width: 450px;
      margin: 0 auto;
      margin-bottom: 2rem;
      font-size: 0.9rem;
      font-weight:300;
      a {
        font-weight:bold;
      }
    }
    input {
      &::-webkit-input-placeholder, &::-moz-placeholder{
        color:transparent;
      }
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color:transparent;
      opacity: 1; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color:transparent;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
      color:transparent;
    }
    
  }

  @include media-breakpoint-desktop {
    .register-form {
      .form-group {
        display:flex;
        justify-content: space-between;
        flex-flow: row wrap;
        .input-group-password {
          width: auto;
        }
        small.form-text {
          width: 100%;
          text-align:right;
        }
        .invalid-feedback {
          text-align:right;
        }
        label {
          &:not(.custom-control-label) {
            padding-top:13px;
            max-width:120px;
          }
        }
        .form-control, .input-group-password, .custom-select {
          max-width:320px;
        }
      }
    }
  }

  @include media-breakpoint-mobile{
    .page-authentication, .page-b2b-create-account, .page-customer-account, #module-b2bregistration-b2b {
        .page-wrapper {
            margin-right: 10px;
            margin-left: 10px;
        }
    }
  }