#product {


    .greenbar-title {
        font-size:1.6rem;
        margin-bottom:2rem;
    }
    
    #main {
        @extend .milani-main-narrow;
        margin-top: 3rem!important;
        margin-bottom:3rem!important;
        color:$midgrey;
        font-size:0.9rem;

        .product-img  {
            border: 1px solid $green;
            border-radius: .25rem;
        }

        .product-thumb {
            border: 1px solid #cfcfcf;
            border-radius: .25rem;
        }

        .card--reassurance {
            img {
                max-width: 20px;
            }
            .h4 {
                font-size:0.8rem;
            }
            li {
                padding-bottom:0!important;
            }
        }
    
        h1 {
            font-size: 2.1rem;
            line-height: 1.1;
        }

        .product-variants, .info-delivery-custom {
            .label, label {
                font-weight:bolder;
            }
        }

        .product-ref {
            font-size:1.6rem;
            &:before {
                border-top: 8px solid $green;
                width: 100px;
                content: '';
                position: absolute;
                top: 0;
            }
        }

        .product-minimal-quantity {
            margin-top:0.4rem;
            font-size:0.84rem;
        }

    .dropdown {
        @extend .milani-dropdown;


        .dropdown-toggle {
            background: white;
            padding: 15px;
            color:$midgrey;
            font-size:0.85rem;
        }
       }
        

        .product-variants-item .color{
            width:3rem;
            height:3rem;
          }

          .product-availability {
              line-height:1.8rem;
          }

          .current-price-display-label {
              font-size:0.9rem;
              line-height: 1.3rem;
          }

          .price {
              font-size:1.5rem;
              line-height: 1.3rem;
          }

          .link-special-color {
              font-size:0.76rem;
              display:block;
              margin-left:65px;
              color:$midgrey;
          }


        .label-quantity{
            font-weight: bold;
            font-size:0.8rem;
        }
        

        .qty {
            &.col-auto {

                background-color:#f2f1f1;
                border-radius:.25rem;
            }
        }

        .bootstrap-touchspin {

            height:45px;
            background-color:transparent;
            .input-group-btn-vertical {

                display:flex;
                flex-direction:column;
                .btn {
                    padding:0 5px;
                    background-color:transparent;
                }

                .bootstrap-touchspin-up {
                    padding-left: 9px;
                    padding-top: 7px;
                }
            }

            input[type=number]::-webkit-inner-spin-button, 
            input[type=number]::-webkit-outer-spin-button { 
                -webkit-appearance: none; 
                margin: 0; 
            }
            
        }
        
        .input-group[name=qty] {
            padding:0;
            font-size:0.8rem;
            background-color:transparent;
            height:45px;
        }

        .btn-add-to-cart {
            height:45px;
        }

    }
}


.product-accessories {
    .products-slider {
        width: 100%;
    }
}

.accordion-product {
    .card-body {
        padding-left:50px;
        font-size:0.85rem;
    }
    .card-header {
        background-color:$white;
        .btn {
            width: 100%;
            text-align:left;
            color: $midgrey;
            font-size: 1.3rem;
            padding-left:30px;
            &:before {
                content: "\e145";
                color: $green;
                font-family: 'Material Icons';
                font-feature-settings: 'liga' 1;
                position: absolute;
                left: 10px;
                transition: .3s transform ease-in-out;
                font-size: 1.5rem;
                top: 17px;
            }

            &[aria-expanded=true] {
                font-weight:600;
                &:before {
                    content: "\e15b";
                    font-weight:normal;
    
                }
            }

        }
    }
    .product-features {
        .h1,.h2,.h3,.h4,.h5,.h6 {
            font-family:$font-family-aglet;
            font-weight:600;
        }
        .h6 {
            font-size: 1rem;
        }
        .data-sheet {
            font-size: 0.8rem;
            padding-left:5px;
        }
    }
    .product-attacchments-list {
        a {
            color: $midgrey;
            display:block;
            font-size:1rem;
            margin-bottom:1rem;
            i {
                color:$green;
                font-size: 1.3rem;
                 margin-right: 12px;
            }
        }
    }

}

#color-modal {
    .modal-title {
        text-transform:uppercase;
        font-size:1.2rem;
    }
    .modal-body {
        a {color:$midgrey;text-decoration:underline;font-weight:600;}
    }
    .color {
        cursor:unset;
    }
    .color-name {
        color: grey;
        text-transform: uppercase;
        font-size: 0.8rem;
    }
}

@include media-breakpoint-mobile {
    #product {
        #main {
            .label-quantity {
                padding-bottom:15px;
            }
        }
    }
}