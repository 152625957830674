.page-content--order-confirmation {
    overflow:hidden;
}

.-unreachable:not(.-current):not(.-complete), .-reachable:not(.-current):not(.-complete) {
    border-top: 2px solid #eee!important;
    .card-header {
        padding:1.4rem 3rem;
        .step-number, .step-title {
            color:#c3c3c3;
        }
    }
}

#bankwire-modal {
    font-size: 0.9rem;
    dl {
        margin-top:1rem;
    }
}

.-current, -complete {
    .step-title {
        color:$greysix;
    }
    .step-number {
        color:$green;
    }
}

.-complete {
    .step-number {
        visibility:hidden;
        padding-right:0;
        &:before {
            visibility:visible;
            font-family: 'Material Icons';
            content: "\e876";
            color:$green;
            vertical-align:middle;
        }
    }
}

.step-edit {
    .fa-pencil-alt {
        font-size: 1.6rem;
        opacity: 0.5;
    }
}

.custom-checkout-info-message {
    background-color: white;
    border-radius: 5px;
    padding: 10px 20px;
    margin-bottom: 15px;
    p {
        margin-bottom: 0;
    }
    strong {
        font-weight: bold;
    }
}

.checkout-step {
    @extend .register-form;
    .form-wrapper, .js-address-form {
     margin-left:unset!important;
     max-width:540px;
    }

    p {
        max-width:100%!important;
        margin-bottom:1rem!important;
    }

    a {
        color:$greysix;
        font-weight:bold;
    }
    border-radius:0!important;
    &:last-of-type {
        border-bottom-right-radius:4px!important;
        border-bottom-left-radius:4px!important;
    }
    &:first-of-type {
        padding-top: 2rem;
        border-top-right-radius:4px!important;
        border-top-left-radius:4px!important;
    }
    .card-header {
        border:0;
        font-weight:700;
        background-color:$white;
    }

    .card-body, .card-header {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .address-selector {
        .card {
            min-width: 220px;
            border: 1px solid #eee;
            height: 100%;
        }
        .card-header, .card-body {
            padding-left:1.25rem;
            padding-right:1.25rem;
        }
        .card-footer {
            display: flex;
            justify-content: space-around;
        }

    }

    .label, label {
        float:left;
    }

    .nav-tabs {
        border:0;
        font-size:0.8rem;
        .nav-item-first {
            border-right: 1px solid $greysix;
        }
        .nav-link {
            font-weight:bold;
            border:0;
            padding-top:0;
            padding-bottom:0;
            &.active {
                color:$greysix;
                font-weight:300;
                border:0;
            }
        }
    }

    .card-body {
        padding-top:0;
    }

     .alert {
         p {
             margin-bottom:0!important;
         }
     }
}

.alert {
    color:$greysix!important;
    border-radius:.25rem;
    font-size:0.8rem;
    p {
        font-weight:300;
        font-size:0.8rem;
        margin-bottom:0!important;
    }
    &.alert-info {
        background-color:#eee;
    }
    border:0;
}

#content-checkout-personal-information-step {
    .nav-tabs {
        line-height: 20px;
        .nav-item {
            a {
                font-weight: normal;
                &.active {
                    font-weight: bold;
                }
            }
        }
    }
}

#content-checkout-payment-step {
    .payment-options {
        font-weight:300;
        padding-left: 2rem;
        margin: 0.5rem 0;
    }
    dl {
        font-size:0.9rem;
    }
}

#order-summary-content {
    .card-body {
        font-size:0.7rem;
    }
}

#content-checkout-delivery-step {

    form {
        max-width: unset;
    }
    .order-options {
        label, textarea {
            width: 100%;
            max-width:unset;
        }
    }
    .delivery-options {
        margin-left: -3rem;
        margin-right:-3rem;
        .row {
            align-items: center;
        }
        .delivery-option {
            padding-left:3rem;
            padding-right:3rem;
            font-weight:300;
            background-color:#eee;
            font-size:0.8rem;
            label {margin-bottom:0;}
        }
    }
}

#order-items {
    font-size:0.85rem;
    .table__title-head {
        font-size:0.85rem;
        white-space: nowrap;
    }
    img {
        border: 1px solid #eee;
        border-radius: .25rem;
    }
}

.page-order-confirmation {
    font-weight:300;

    p {
        a {
            color:$midgrey;
            font-weight:bold;
        }
    }
    .greenbar-title {
        padding-left:2rem;
        &:before {
            content: '\e876';
            font-family: 'Material Icons';
            color:$green;
            position:relative;
            border:0;
            margin-right:1rem;
        }
    }
    .order-confirmation-table {
        border:0;
        font-size:1rem;
        .qty { font-weight:bold; }
    }
    hr {
        margin-right:-3rem;
        border-top:4px solid rgba(0,0,0,0.1);
    }
    #order-items .table__title-head {
        font-size:1.1rem;
        white-space:unset;
    }

    .table-totals {
        width: calc(100% + 6rem);
        margin-right:-3rem;
        margin-left:-3rem;
        .final-total {
            font-size:1.3rem;
            font-weight:bold;
            line-height:22px;
        }
        tr {
            td:first-child{
                padding-left:3rem;
            }
            td:last-child {
                padding-right:3rem;
            }
        }
    }
}

@media (max-width: 550px){

    #content-checkout-payment-step {
        .payment-options {
            padding-left: 1rem;
        }
    }

    #order-summary-content {
        .card-header, .card-body {
            padding-left:0;
            padding-right:0;
        }
    }

    .-unreachable:not(.-current), .-reachable:not(.-current) {
        .card-header {
            padding:1.4rem 2rem;
        }
    }
    .checkout-step {
        &:first-of-type {
            padding-top: 1rem;
        }
        label, .label {
            font-size: 0.75rem;
        }
        .card-body, .card-header {
            padding-left: 2rem;
            padding-right: 2rem;
        }
        .step-title {
            font-size:1.1rem;
            position:relative;
            .fa-pencil-alt {
                font-size:1rem;
                position:absolute;
                right:-5px;
                top:2px;
            }

        }
    }
    .address-selector {
        .card {
            width: 100%;
        }
    }
    #content-checkout-delivery-step {

        .delivery-options {
            margin-left: -2rem;
            margin-right:-2rem;
            .delivery-option {
                padding-left:2rem;
                padding-right:2rem;
                .text--right {
                    text-align:left;
                }
            }
        }
    }
}

@include media-breakpoint-mobile {
    .table-totals {
        width: calc(100% + 4rem)!important;
        margin-right:-2rem!important;
        margin-left:-2rem!important;
    }
}

@media (max-width:1199px){
    .page-order-confirmation {
       
        .order-confirmation-table {
            font-size:0.8rem;
        }
        #order-items .table__title-head {
            font-size:0.9rem;
        }
    }
}

@media (max-width:1024px){
    #content-checkout-payment-step {
        .table td {
            font-size:0.8rem;
        }
    }
}

@media (max-width:380px){
    .step-edit {
        visibility: 0;
        .material-icons {
            visibility: visible;
        }
    }
}