
body.page-category {
    &:not(.category-depth-level-4) {
        background-color:#F8F8F8;
    }
}

.block-category {
    height:390px;
    padding-top:80px;
    background-color:$greysix;
    background-size:cover;
}

.block-category-description {
    max-width:386px;
    color:$white;
    font-weight:300;
    font-size:1.1rem;
}

.block-category-title {
    font-size:2.3rem!important;
    &:before {
        top:50%!important;
    }
}


.block-category-grid {
    .col {
        @extend .milani-card;
        max-width:340px;
        max-width:30%;
        position:relative;
        img {
            max-width:90%;
        }
        &:hover {
            .grid-link {
                @extend .milani-card-link-active;
            }
        }
    }

    .grid-link {
        @extend .milani-card-link;
    }
}

.category-depth-level-3 {
    .block-category-grid {
        .grid-link {
            text-transform:uppercase;
        }
    }
}

.block-category-grid-featured {

    .featured-label {
        &:after {
            width:130px;
            border:0;
        }
        position: absolute!important;
        padding: 3px 20px 3px 30px;
        color: white;
        background-color:$green;
        left: -20px;
    }
    .col { 
        max-width:47%;
    }
}


.block-category-featured-top {
    border: 1px solid $green;
    margin: -20px auto 0 auto;
    background:$white;
    font-weight:300;
    height:300px;
    font-size:1.1rem;
    h3 {
        font-family:$font-family-aglet;
    }

    h3 {
        font-weight:600;
        font-size:1.5rem;
        margin: 1.3rem 0;
    }

    .featured-box-text {
        max-width:50%;
        float:left;
    }

    .featured-box-image {float:right; max-width:40%;max-height:90%;}
}

.block-category-featured-content-wrapper {
    position:absolute; width:100%; height:100%; overflow-y:hidden;
}

.featured-label {
    font-weight: 600;
    font-size:0.9rem;
    &:after {
        right: -3px;
        border-bottom: 5px solid #C9DB57;
        width: 175%;
        content: '';
        position:absolute;
        bottom: -5px;
    }
}

.testo-seo {
    h1,h2,h3,h4,h5,h6 {
        font-family:$font-family-aglet;
        font-weight: 600;
    }
    font-size:0.9rem;
    img {
        object-fit: contain;
    }
}

/** FAMIGLIA */
.block-famiglia-title {
    color:$midgrey;
    font-size:2.5rem;
}

.block-famiglia-description {
    color:#525252;
    .responsive-only-image {
        height: auto;
        width: 100%;
    }
}

@include media-breakpoint-mobile() {

    .block-category-title, .block-category-description {
        margin-left:0;
    }

    .block-category {
        padding: 80px 15px 15px 15px;
    }

    .block-category-title {
        font-size:2rem!important;
    }

    .block-category-grid {
        .col {
            max-width: 46%;
            padding-bottom:70px!important;
        }
    }

    .block-category-grid-featured {
        .col { 
            max-width:100%;
        }
    }
}
