.l-header{
  background-color: $header-bg;
  margin-bottom: $spacer;
}

.breadcrumb-nav {
  height:35px;
  background-color:$midgrey;
  margin-top: 30px;
}

.breadcrumb {
  text-transform:uppercase;
  font-family: $font-family-aglet;
  color:$white;
  background-color:$midgrey;
  padding:0;
  line-height:35px;
  font-size:0.7rem;

  font-weight:600;

  .breadcrumb-item {
    &:not(:first-child) {
      &::before {
        color:$white;
        content: ">";
        font-weight:600;
      }
    }
    a {
      color:$white;
      font-weight:normal;
    }
    &.active {
      color:$green;
    }
  }
}
.header__container{
  background-color: $lightgrey;
  padding-left: 0;
  padding-right: 0;
}
.header__up{
  align-content: center;
}

.logo {
  max-width: 154px;
  padding-top:15px;
  margin-bottom:-20px;
}

.header-top{
  flex-wrap: wrap;
  /*padding-top: $spacer*1.25;*/
  padding-bottom: $spacer*1.25;
}

.burger-menu-icon {
  color:$green;
}

.header__search{
  flex: 1;
  padding-right: $grid-gutter-width/2;
  padding-left: $grid-gutter-width/2;
}
.header__right {
  margin-left: auto;
  .blockcart__icon {
    color:$midgrey;
    &:hover,&:active {
      color:$green;
    }
  }
}
.header__right,
.header-nav__right{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header__logo {
  max-width: 20%;
}
.header__menu {
  margin-left: 0;
  padding-left: 0;
  max-width: 70%;
}
.header__right {
  margin-left: auto;
  max-width: 20%;
  z-index: 99;
  .user-info.header__rightitem {
    display: flex;
    .visible--desktop.small {
      display: none;
    }
  }
}

.header-nav {
  .user-info, .blockcart, .shopping-cart {
    display:none;
  }
}
.header__rightitem:not(:last-child){
  margin-right: $spacer/2;
}
.header__logo--checkout{
  max-width: 200px;
}
.header-nav--checkout{
  padding-top: $spacer;
  padding-bottom: $spacer;
}
.header-desktop {
  background-color:$lightgrey;
  color: $darkgrey;
  height:53px;
}

.header-contact-links {
  .material-icons {
    color:$green;
    font-size: 1.2rem;
    margin-top: 2px;
    margin-right: 5px;
  }
  a {
    font-size:0.9rem;
    padding-right:4rem;
    line-height:2rem;
  }
}

.language-selector-wrapper, .currency-selector {
  .btn {
  color:$midgrey;
  }
  .dropdown-item,.dropdown-item.active, .dropdown-item:active {
    color:$midgrey;
    background-color:$white;
  }
}

@media only screen and (min-width: 1301px) {
  .logo {
    /*margin-bottom: -25px;*/
  }
}

@media only screen and (min-width: 1231px) and (max-width: 1300px) {
  .logo {
    max-width:125px;
    /*margin-bottom: -18px;*/
  }
}

@media only screen and (min-width:992px) and (max-width: 1414px) {
  .logo {
    max-width:100px;
    margin-bottom: -10px;
  }
  .header__logo {
    padding-left:50px;
  }
  .header__right {
    padding-right:50px;
  }
  .header-desktop {

    padding-right:54px;
    padding-left:54px;
  }
}

@media only screen and (min-width: 1415px){
  .header__logo {
    padding-left:84px;
  }
  .header__right {
    padding-right:84px;
  }

  .header-desktop {
    padding-left:84px;
    padding-right:84px;
  }
}

@include media-breakpoint-desktop{
  .l-header {
    height: 190px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .header__right {
    margin-left: auto;
  }
  .material-icons {
    font-size: 1.7rem;
  }
  .header-top{
    /*height:100px;*/
  }
}

@include media-breakpoint-mobile{
  .header-contact-links {
    .u-link-body {
      display:block;
    }
  }
  .breadcrumb {
    padding-left:30px;
    padding-right:20px;
    margin-left: -15px;
    margin-right: -15px;
    display:flex;
    flex-wrap: nowrap;
    max-height: 35px;
    overflow: hidden;
    .breadcrumb-item {

      &.first-breadcrumb-mobile {
        &::before {
          content:'';
        }
      }
      a {
        display: block;
        max-width: 120px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

    }
  }
  .logo {
    max-width: 104px;
    margin-left:5px;
    padding-top:0;
  }

  .modal-header {
    .logo {
      margin-top:5px;
    }
  }

  .header__logo,
  .header__logo--checkout{
    max-width: 50%;
  }
  .header__search {
    width: 100%;
    padding: 0;
    flex: auto;
    order: 10;
    margin-top: 10px;
  }
  .header__logo--checkout{
    margin: 0 auto;
  }
}

.header-banner div{
  text-align: center;
  padding: 10px 0;
  font-size: 0.9rem;
  font-family: aglet-sans,sans-serif;
  text-transform: uppercase;
  background-color: #4D4E4C;
  color: #C9DB57;
  max-width: unset;
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  right: 50%;
  width: 100vw;
}
