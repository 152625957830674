
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../css/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url(../css/MaterialIcons-Regular.woff2) format('woff2'),
  url(../css/MaterialIcons-Regular.woff) format('woff'),
  url(../css/MaterialIcons-Regular.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: $font-size-base*$line-height-base;  /* Preferred icon size */
  display: inline-block;
  vertical-align: top;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
//fix height
.material-icons + span{
  display: inline-block;
}
.small .material-icons,
.btn-sm .material-icons{
  font-size: $small-font-size;
  line-height: $line-height-base;
}
.btn-lg .material-icons{
  font-size: $font-size-lg*$btn-line-height-lg;
}

.pagination .material-icons{
  font-size: $font-size-base*$pagination-line-height;
}
.material-icons.md-18,
{ font-size: 18px; line-height: 1}
.material-icons.md-24,
{ font-size: 24px; line-height: 1}
.material-icons.md-36,
{ font-size: 36px; line-height: 1}
.material-icons.md-48,
{ font-size: 48px; line-height: 1}


.font-aglet {
  font-family: aglet-sans, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-ubuntu {
  font-family: $font-family-ubuntu;
}

.font-opensans {
  font-family: $font-family-sans-serif;
}

.font-light {
  font-weight:300;
}

.font-medium {
  font-weight:500;
}

.font-semibold {
  font-weight:600;
}

.font-bold {
  font-weight:bold;
}