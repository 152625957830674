.search-form * {
  outline: none;
  box-sizing: border-box;
}
.search-wrapper {
  position: relative;
}
.search-input {
  height: 50px;
  line-height: 1.2rem;
  line-height: normal;
  padding: 0.35em 50px 0.35em 1rem;
  border-radius: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.search-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 38px;
  height: 50px;
  text-align: center;
  display: inline-block;
  transition: background-color 0.2s ease-in-out;
  color:$midgrey;
  &:hover, &:active {
    color:$green;
  }
  &:focus {
    outline:none;
  }
}
.search-input::-webkit-input-placeholder {
  position: relative;
  top: 0;
  left: 0;
  transition-property: top, color;
  transition-duration: 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}
.search-input:-moz-placeholder {
  position: relative;
  top: 0;
  left: 0;
  transition-property: top, color;
  transition-duration: 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}
.search-input::-moz-placeholder {
  position: relative;
  top: 0;
  left: 0;
  transition-property: top, color;
  transition-duration: 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}
.search-input:-ms-input-placeholder {
  position: relative;
  top: 0;
  left: 0;
  transition-property: top, color;
  transition-duration: 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}
.search-input::-webkit-input-placeholder[style*="hidden"] {
  color: #83b0c1;
  font-size: 0.65em;
  font-weight: 400;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}
.search-input:-moz-placeholder[style*="hidden"] {
  color: #83b0c1;
  font-size: 0.65em;
  font-weight: 400;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}
.search-input::-moz-placeholder[style*="hidden"] {
  color: #83b0c1;
  font-size: 0.65em;
  font-weight: 400;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}
.search-input:-ms-input-placeholder[style*="hidden"] {
  color: #83b0c1;
  font-size: 0.65em;
  font-weight: 400;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}
/*
@media (max-width: 992px) {
  .search-input {
      width: 100%;
      border: 1px solid #ccc;
  }
  .search-icon {
      border: 1px solid #ccc;
      border-left: 0;
  }
  .lang-dropdown {
      border: 1px solid #ccc;
      margin-bottom: 1rem;
      margin-top: 1rem;
      height: 50px;
      font-size: 1.2rem;
      line-height: 1.2rem;
      padding: 0.35em 1rem;
  }
  .lang-dropdown .dropdown-toggle:after {
      float: right;
      font-size: 1.5rem;
      margin-top: 6px;
  }
  .lang-dropdown-item {
      line-height: 1.7rem;
  }
  .lang-dropdown-menu {
      top: -3px;
  }
}
@media (min-width: 992px) {*/
  .navbar {
      height: 90px;
  }
  .search-input {
      width: 50px;
      border: 0;
      border-color:transparent;
      
  }
  .search-input:focus {
      border: 1px solid $green;
      border-radius: 4px;
      width: 220px;
      color: $midgrey;
      cursor: default;
      box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  }
  .search-icon {
      pointer-events: none;
      background-color: transparent;
      border-color: transparent;
  }
  .search-input:focus ~ .search-icon {
      background-color: transparent;
      cursor: pointer;
      pointer-events: auto;
  }
/*}*/
@media (min-width: 991px) and (max-width: 1199px) {
  .search-input:focus {
    width: 160px;
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .search-input:focus {
    width: 180px;
  }
}
@media (max-width:990px) {
  
  .search-input:focus {
    //width: 53vw;
    //height:35px;
  }

  .search-input:focus ~ .search-icon { 
    //height:35px;
  }

}
