body{
  background-color: $wrapper-bg;
  overflow-x:hidden;
}

img:focus, a:focus {
    outline:none;
}

.popover {
  border-radius: .25rem;
}

.cart-empty div#content-wrapper {
  margin: 0 auto;
  max-width: 1447px;
}

main {
  @extend .container;
  max-width: unset;
  padding-left:0;
  padding-right:0;
}

a{
  text-decoration: none;
}
sup{
  top:-0.3em;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color:$pinkred;
  color: $pinkred;
}

.invalid-feedback {
  color:$pinkred;
  font-size:0.75rem;
  font-weight:600;
  margin-top:10px;
}

small.form-text {
  font-size:0.7rem;
  font-style: italic;
}

.form-control {
  font-weight:600;
  box-shadow:none;
  border: 1px solid #C9C4C4;
  border-radius: 4px;
  height:50px;
  padding-left:1.5rem;
  padding-right:1.5rem;
  &:active, &:focus {
    border: 1px solid $green;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  }
}


.btn,.btn:focus,.btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus,.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  box-shadow:none;
}

.alert {
  a {color:$greysix;font-weight:600;}
}

.color {
  box-shadow:none;
}

.milani-dropdown {
  .dropdown-menu {
      font-size:0.85rem;
      padding:0;
      border-bottom-left-radius: .25rem;
      border-bottom-right-radius: .25rem;
  }

  .dropdown-toggle {
    border-radius:.25rem;
    border: 1px solid #D1D1D1;
    &::after {
      font-family:'Material Icons';
      content: '\e313';
      color:$green;
      border-top:0;
      margin-right: -10px;
      font-size:1.1rem;
      border: 0!important;
    }
  }

  .dropdown-item {
    border-top:1px solid #D1D1D1;
    padding: 1rem 1.5rem;
    &:hover, &.active {
      background-color:$darkgrey;
      color:$green;
    }
  }
}

.milani-card {
  border:1px solid $green;
  background:$white;
  border-radius: .25rem;
  position:relative;
  text-align:center;
}

.milani-super-narrow {
  max-width:1279px;
  margin: 0 auto;
  width:100%!important;
  padding: 0 15px;
}

.milani-main-narrow {
  max-width:1050px;
  margin:0 auto 7rem auto;
}

.milani-1160 {
  max-width: 1160px;
  margin: 0 auto;
}

.milani-main {
  padding-left:84px;
  padding-right:84px;
}

.milani-full-width {
  margin-left:-84px;
  margin-right:-84px;
  padding-left:84px;
  padding-right:84px;
}

.milani-card-link {
  font-family:$font-family-aglet;
  font-weight:600;
  color:$midgrey;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  display: block;
  padding: 15px 5px;
  line-height:1.2;
  text-align:center;
  &:hover, &:active {
      background-color: $midgrey;
      color: $green;
      border-bottom-left-radius: .25rem;
      border-bottom-right-radius: .25rem;
  }
}

.milani-card-link-active {
  background-color: $midgrey;
  color: $green;
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.btn {
  &:disabled {
    background-color:#D9D9D9;
    color:#888585;
    &:hover, &:active {
      background-color:#D9D9D9;
      color:#888585;
  }
  }
}

.btn-imilani {
  border-radius:2px;
  border-color:transparent;
  box-shadow:none;
  padding: 0.6rem 2rem;
  font-size:0.8rem;
  text-transform:uppercase;
}

.btn-lightgrey {
  @extend .btn-imilani;
  color: $midgrey;
  background-color:#E2E2E2;
  &:hover:not(:disabled), &:active:not(:disabled) {
    background-color: #afafaf;
  }
}

.btn-transparent {
  @extend .btn-imilani;
  background-color:transparent;
  color:$white;
  border-color:$white;
  border: 2px solid $white;
  &:hover:not(:disabled), &:active:not(:disabled) {
    background-color: transparent;
    color:$white;
    border: 2px solid $white;
  }
}

.btn-primary, .btn-outline-primary, .btn-grey {
  @extend .btn-imilani;
  background-color:$midgrey;
  color:$green;
  &:hover:not(:disabled), &:active:not(:disabled) {
    background-color: #2D2E2C;
    color:$green;
    border-color:#2D2E2C;
  }
}

.btn-outline-primary {
  padding: 5px;
}


.btn-green {
  background-color:#A7C812;
  color:$white;
  @extend .btn-imilani;
  &:hover:not(:disabled), &:active:not(:disabled) {
    background-color: #75AF08;
    color:$white;
    border-color:#75AF08;
  }
}

.greenbar-title {
  padding-left:92px;
  position:relative;
  &:before {
    content: '';
    border-bottom: solid 5px $green;
    position: absolute;
    left: 0;
    top: 40%;
    z-index: 1;
    width:80px;
  }
}

#content-wrapper {
  padding-left:0;
  padding-right:0;
}

.l-wrapper{
  @if $enable-fluid-layout {
    @extend .container-fluid;
  } @else {
    @extend .container;
  }

  &--boxed{
    @extend .container;

  }
}

#wrapper {
  padding-left: 15px;
  padding-right: 15px;
}

#module-b2bregistration-business #wrapper,
#module-b2bregistration-b2b #wrapper,
.page-order #wrapper,
.page-cart #wrapper,
.page-order-confirmation #wrapper,
.page-addresses #wrapper,
.page-customer-account #wrapper,
.page-my-account #wrapper {
  margin: 150px auto 0 auto !important;
}
#module-b2bregistration-b2b,
.page-addresses,
.cart-empty,
.page-cart,
.page-order-confirmation,
.page-order,
.page-b2b-create-account,
.page-my-account,
.page-customer-account {
  #wrapper {
    margin: 0 auto;
    max-width: 1447px;
    &:not(.notifications-container) {
      background-image: url(../img/imilani/imiLOG-IT-sbloccato-22_trasp_alta.png);
      background-repeat: no-repeat;
      background-color: #4D4E4C;
      background-position: center top;
      background-size: cover;
    }
  }
}
.card-block{
  @extend .card;
}

.page-header{
  h1{
    margin-bottom: 0;
  }
  border:unset;
  background:transparent;
}
.page-content,.card-block{
  @extend .card-body;
}
.card-block{
  box-shadow:$card-box-shadow;;
}
.page-footer{
  font-family:$font-family-aglet;
  text-transform:uppercase;
  font-weight: 600;
  @extend .py-3;
  @extend .mb-3;
  a {
    margin-right:1rem;
    &:hover {
      color: #75AF08;
    }
  }

  &:empty{
    display: none;
  }
}



.left-column > div,
.right-column > div{
  margin-bottom: $spacer;
}
/* HEADER */

/* FOOTER */
.l-footer.container {
  background-color: #bd4147;
}
.l-footer{
  background-color: $footer-bg;
  margin-top: $footer-margin-top;
  padding-top: $footer-padding-top;
}

/* MISC */
ul {
  list-style: none;
  padding-left: 0;
}
h1,.h1{
  text-transform: $h1-text-transform;
  font-size:2rem;

}
.quick-view{
  display: inline-block;
  @extend .small;
}
.form-group.form-group_forgot-password{
  margin-top: -($form-group-margin-bottom/2);
}

.form-check-radio {
  margin-right: auto;
}

.forgot-password {
  text-decoration: underline;
  a {color:$greysix;}
}

.products-slider {
  overflow:hidden;
  .product-miniature {
    max-width:180px!important;
  }
}
.products-slider, .caso-products-slider {

  padding-left: 50px;
  padding-right:50px;
  margin:0 auto;

  .slick-track { 	display: flex !important; }
  .slick-track > .slick-slide {
    height: inherit !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .slick-track > .slick-slide > div { height: 100%; }

  .price {
    font-size:0.9rem!important;
  }

  .product-title {
      font-weight:600!important;
      font-size:0.9rem;
      overflow:hidden;
  }

  .slick-slide > div {
      text-align:center;
      width: 100%;
  }

  .product-miniature {
      @extend .milani-card;
      height:315px;

      .card-img-top, .card-body {
          padding:1rem
      }

      .card-body {
        &:hover {
          cursor:pointer;
        }
      }
  }

  .product-price-and-shipping {
      font-size: 0.75rem;
      white-space: nowrap;
      overflow:hidden;
  }

  .price {
      font-weight:700;
      font-size:0.85rem;
  }

  .card-product {
    &:hover {
      .card-body {
        margin: -1px;
        padding: calc(1rem + 1px);
        &, .highlighted-informations {
            border-bottom-left-radius: .25rem;
            border-bottom-right-radius: .25rem;
            background-color:$greysix;

            .product-price-and-shipping {
                color:$white;
            }
        }
    }
    }
  }



  .slick-arrow > i {
      background:$greysix;
      color: $white;
      font-size: 1.4rem;
  }
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: 7px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 15px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'Material Icons';
    content: "\e3a6";
    font-size: 10px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;
    text-align: center;

    color: $white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    color: $green;
}

@media (min-width: 1200px){
  .products-slider, .caso-products-slider {
    width: 80%;
  }
}

@media (max-width: 1199px){
  .products-slider, .caso-products-slider {
    padding-left: 45px;
    padding-right:45px;
  }
}

@media (max-width:991px){

    .products-slider, .caso-products-slider {
        padding-left: 25px;
        padding-right:25px;
        margin-left:0px;
        margin-right:0px;
        width: 100%;
    }

}

@media (max-width:768px){

  .products-slider, .caso-products-slider {
      padding-left: 20px;
      padding-right: 20px;
  }

  #module-b2bregistration-business #wrapper,
  #module-b2bregistration-b2b #wrapper,
  .page-order #wrapper,
  .page-cart #wrapper,
  .page-order-confirmation #wrapper,
  .page-addresses #wrapper,
  .page-customer-account #wrapper,
  .page-my-account #wrapper {
    margin: 0 auto !important;
  }

  .block-famiglia-title.greenbar-title {
    font-size: 28px;
  }

}

//touch spin

.bootstrap-touchspin
{
  /*box-shadow: $bs-touchspin-boxshadow;
  border:$input-border-width solid $input-border-color;*/
  > input {
    max-width: 60px;
    border:none;
    box-shadow: none;
    text-align: center;
    font-weight: 700;
  }
  .btn {
    position: relative;
    z-index: 2;
    color:$input-color;
    background-color: $white;

  }
  .input-group-btn{
    display: flex;
    &:first-child{
      margin-right:-$input-border-width;
    }
    &:last-child{
      margin-left:-$input-border-width;
    }
  }
}

.btn-touchspin-custom {
  visibility: hidden;
  border:0;
  &:before {
    visibility: visible;
  }
}

/* minimizing reflows and avoiding page jumps */

.rc {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  background: rgba(0,0,0,0.05);
  transition: background 150ms;
  &:after{
    @extend .spinner-border;
    content: "";
    border-color: $gray-300;
    position: absolute;
    top: calc(50% - #{($spinner-height/2)});
    left: calc(50% - #{($spinner-width/2)});
    z-index: 2;
    border-right-color: transparent;
  }
  &--lazyload:after{
    display: none;
  }
}

.rc > * {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.rc > a {
  display: block;
}
.ratio2_1{
  padding-top: 50%;
}
.ratio1_2{
  padding-top: 200%;
}
.ratio4_3{
  padding-top: 75%;
}
.ratio16_9{
  padding-top: percentage(9/16);
}
.ratio1_1{
  padding-top: 100%;
}
.ratio3_2{
  padding-top: percentage(1-2/3);
}

/* collapse icons */
.icon-collapse{
  transition: transform 0.4s ease;


}
.icon-collapse[aria-expanded=true]{
  transform: rotateZ(180deg);
}

/* CHECKOUT STEP */
.order-checkout .card-body{
  padding-bottom: 0;

}
.step-edit[aria-expanded="true"]{
  display: none;
}
.step-title
{
  margin-bottom: 0;
  font-family:$font-family-aglet;
  font-size:1.4rem;
}
.step-number {
  color:$green;
  padding-right:0.6rem;
}
.-complete.-reachable .step-title .done {
display: inline-block;
}
.-current .step-title .done {
  visibility: hidden;
}
.-current .card-header{
  background-color: $white;
}


.accordion {
  .card-body .card {
    border: 1px solid $card-border-color;
    transition: border 0.1s linear;

  }
  .card-body .card-header{
    text-align: center;
  }
  .selected .card{
    border-color: $success;
    border-width: 2px;
  }
}
  //Personnal info
  .nav-tabs-info{
    font-size: $font-size-base*1.1;
    .active{
      font-weight: bold;
    }
  }

  //Addresses

  .address-selector
  {
    .card{
      transition: border 0.1s linear;

    }
    .card-header,
    .card-body{
      cursor: pointer;

    }
  }
  .card-body_add-address{
    padding: $card-spacer-y $card-spacer-x;
    .material-icons{
      opacity: .8;
    }
  }
  // SHipping
.delivery-option{
  padding: $spacer;
  margin-bottom: $spacer;
  background-color: $gray-200;
}
.carrier-extra-content:not(:empty){
  margin-bottom: $spacer*2;
  &:empty{
    display: none;
  }
}
.carrier-name{
  font-weight: $font-weight-bold;
}
  .carrier-price{
    font-weight: bold;
    color: $greysix;
  }
//order list final recap
.order-line:not(:last-child){
  margin-bottom: $spacer/2;
}
.order-confirmation-table{
  padding: $spacer;
  border: 3px solid $card-border-color;
}


//forms
custom-file-label.selected:after{
  content: "" !important;

}
.label{
  /*@extend label;*/
  /*display:none;*/
}
//nav tabs
.nav-tabs  {
  .nav-link{
  color:$gray-600;
  font-weight: 700;
  }
  .nav-link.active,
  .nav-item.show .nav-link {
    border-bottom-width: 3px;
  }
}
.tab-content{
  padding-top: $spacer;
}
.card--reassurance {
  img {
    opacity:0.6;
  }
}
//products-section-title
.products-section-title{
  text-transform: $section-title-text-transform;
  text-align: $section-title-text-align;
  display: block;
  margin-bottom: $section-title-margin-bottom;
  @extend .h1;
  font-family: $section-title-font-family;
  color:$section-title-color;
  font-size:$section-title-size;
  font-weight:$section-title-font-weight;
}

//home content spacing
.page-home > *{
  margin-bottom: $spacer-y;
}

//b2b registration loader
.b2b-loader-overlay {
  background: grey;
  opacity:0.1;
  position: absolute;
  text-align:center;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 10005;
}
.b2b-loader-spinner {
  position:absolute;
  top: 150px;
  text-align:center;
  width: 100%;
}

@include media-breakpoint-mobile() {

  .page-footer {
    a {
      display:block;
      margin-bottom:1rem;
    }
  }

  .milani-main {
    padding-left:15px;
    padding-right:15px;
  }

  .milani-full-width {
    margin-left:-15px;
    margin-right:-15px;
    padding-left:25px;
    padding-right:25px;
  }

  .modal-footer{
    flex-direction: column;
    flex-direction:column-reverse;
    & > .btn{
      margin: 0;
      width : 100%
    }
    & .btn:not(:last-child){
      margin-top: $spacer/2;
    }

  }

  .l-wrapper-bgimage {
    &:not(.notifications-container) {
      background-position:50%;
      }
  }

}

.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    display: none;
  }
  .nav-link {
    white-space: nowrap;
  }
}

//forms

.custom-checkbox {
  font-weight: 300;
  font-size: 0.85rem;
}
.notifications-container{
  ul{
    margin-bottom: 0;
  }
}
.js-invalid-feedback-browser:empty{
  display: none;
}
.form-group{
  label,.label{

  small,
  .small{
    font-size: .75 * $font-size-base;
    font-style:itailc;
  }
  }
}
@include media-breakpoint-desktop{

  main > .notifications-container,
  .l-wrapper{
    margin-top: 160px;
  }

.page-content,
.checkout-form,
.page-wrapper--order-confirmation{
  form{
    max-width: 450px;
    margin: 0 auto;
  }
}
.page-wrapper--order-detail{
  form{
    margin: unset;
  }
}
}

.form-fields {
  &:after {
    display: block;
    clear: both;
    content: "";
  }
}
.form-footer{
  margin-top: $spacer;
}

// badge order history
.badge.bright{
  color:#fff;
}
