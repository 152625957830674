.page-content--cms > h1, .page-content--cms > h2, .page-content--cms > h3, .page-content--cms > h4 {
    font-family:$font-family-aglet;
    text-transform:uppercase;
}
.page-content--cms > h1 {
  font-size:2.2rem;
}
.page-content--cms > h2 {
  font-size:2rem;
}
.page-content--cms > h3 {
  font-size:1.8rem;
}
.page-content--cms > h4 {
  font-size:1.5rem;
}

.page-history {
  .badge {
    white-space: normal;
  }
}


.orders.visible--mobile {
  a {
    h3 {
      color:$greysix;
      font-weight:bold;
    }
  }
}

.order-items.visible--mobile {
  .name {
    font-size:0.8rem;
  }
  .order-item {
    padding: 15px 0;
  }
}

.psgdprinfo17 {
  p {
    a {
      color:$midgrey;
      font-weight:bold;
    }
  }
}

.page-contact, .page-identity, .page-my-account, .page-password, .page-authentication, .page-b2b-create-account, #module-b2bregistration-b2b {
  #main {
    @extend .milani-main;
    padding-top:3rem;
    padding-bottom:3rem;
  }
  h1, h2, h3, h4, h5 {
    font-family:$font-family-aglet;
    text-transform:uppercase;
  }
  .page-header {
      margin-bottom:1.5rem;
  }
  p {

    font-weight:300;
  }
  .custom-file-input {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
  }

  .custom-file-label {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      font-family:$font-family-aglet;
      text-transform:uppercase;
      font-size:0.8rem;
  }
}

.contact-form {
  .dropdown {
    @extend .milani-dropdown;
    display:block!important;
    width: 100%!important;
    .dropdown-toggle {
      background:$white;
      .filter-option-inner {
      padding: .375rem .75rem;
      font-size: 1rem;
      }
    }
  }
}

.page-addresses {
  .address-footer {
    a {
      color:$greysix;
      font-family:$font-family-aglet;
      text-transform:uppercase;
    }
  }
}

.contact-rich {
  font-weight:300;
  h4 {
    margin-bottom:1.5rem;
  }
  a {
    color:$greysix;
    font-weight:600;
  }
  .icon {
    float:left;
    margin-right:7px;
  }

  .address-other {
    margin-top: 80px;

    .address-other-box {
      margin-bottom: 30px;
    }
  }
}

.account-links {
  .link-item {
    color:$greysix;
    &:hover {
      text-decoration:bolder;
    }
    .material-icons {
      color:$green;
    }
  }
}
.page-cms {
  .breadcrumb-nav {
    display:none;
  }

  .block-category {
    color:$white;
    .elementor-widget-heading .elementor-heading-title, .elementor-text-editor p {
      color:$white;
    }
    h2.elementor-heading-title {
      font-weight:300;
      margin-bottom:0;
      line-height: 1.2;
    }
    h3.elementor-heading-title {
      font-size:2.5rem;
      text-transform:uppercase;
      font-weight: 600;
      padding-left:92px;
    }
  }
}
//pagine elementor

.elementor {
  padding:0;
  color:$midgrey;
  font-size: 1.2rem;
  line-height: 1.5;


  .elementor-column-gap-default>.elementor-row>.elementor-column>.elementor-element-populated {
    padding:0;
  }

  p {
    font-weight: 300!important;
  }


  .elementor-widget-heading .elementor-heading-title   {
    color:$midgrey;
    font-family:$font-family-aglet;
  }
  h1.elementor-heading-title {
    font-size:2.5rem;
    @extend .greenbar-title;
    text-transform:uppercase;
    font-weight: 600;
  }
  h2.elementor-heading-title {
    font-size:2rem;
    @extend .greenbar-title;
    text-transform:uppercase;
    font-weight:300;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .elementor-widget-button  .elementor-button {
    @extend .btn;
    @extend .btn-grey;
    font-family:$font-family-aglet;
    &.elementor-size-lg {
      font-size:1.1rem;
    }
  }
}
.elementor-section {
  margin-bottom:3rem;
}

.pagine-static-narrow {
  max-width: 850px;
  margin: 0 auto 3rem auto;
}
.pagine-static-super-narrow {
  max-width: 550px;
  margin: 0 auto 3rem auto;
}

.elementor-col-50 {
  .elementor-widget-wrap {
    padding-left:2rem;
    padding-right:2rem;
  }
}
//referenze
.casi-studio-slider {
  background-color:#F8F8F8;
  padding: 4rem;
  .slick-arrow {
    width: 20px!important;
    height:20px;
    background-color:$greysix!important;
    border-radius:10px;
    &.slick-prev {
      left:-45px;
    }
    &.slick-next {
      right:-45px;
    }
    &:before {
      font-size: 19px!important;
      padding-left:6px;
    }
  }
}
.casi-studio-gallery {
  background-color:#F8F8F8;
  padding: 2rem 3rem;
  .elementor-image-box-wrapper {
    width: 90%;
    margin: 4rem auto;
  }
  .elementor-image-box-wrapper .elementor-image-box-img {
    width: 100%!important;
    img {
      border-radius: .25rem;
    }
  }
  .elementor-image-box-img {
    margin-bottom:0!important;
  }
  .elementor-image-box-wrapper {
    position:relative;
  }
  .elementor-image-box-content {
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    border-radius: .25rem;
    display:none;
      h3 {
        padding-top: 30%;
        font-family:$font-family-aglet;
        color:$white!important;
        font-weight:600;
        text-transform:uppercase;
      }
  }
}


.box-links-casi {
  .elementor-image-box-wrapper {
    position:relative;
  }
  .elementor-image-box-content {
    display:none;
    position:absolute;
    bottom:0;
  }
  .elementor-flip-box-image img {
    width: 100%;
  }
  .elementor-flip-box-content>:not(:last-child) {
    margin-bottom:0;
  }
  .elementor-flip-box-front, .elementor-flip-box-back {
    background-color:transparent;
  }
  .elementor-flip-box-content {
    position:relative;
    a {
      position:absolute;
      width: 100%;
      height: 100%;
      top:0;
      left:0;
      .elementor-button-text {
        display:none;
      }
    }
    h3 {
      position:absolute;
      top:30%;
      font-weight:300;
      text-transform:uppercase;
      font-size:0.9rem;
      left:1rem;
      right:1rem;
      &:after {
        content: '';
        border-bottom:3px solid $green;
        width: 40%;
        position: absolute;
        bottom: -20px;
        left: 30%;
        right: 30%;
      }
    }
    .elementor-flip-box-description {
      position:absolute;
      bottom:20%;
      left:1rem;
      right:1rem;
      font-family:$font-family-aglet;
      font-size:1rem;
      font-weight:600;
    }
  }
}

//casi studio
.caso-products-slider {
  .product-miniature {
    flex:unset!important;
    max-width:unset!important;
  }
}

// chi siamo

.bg-grey-scatole {
  margin-top:-20px;
  text-align:center;
  background: $greysix url(../img/imilani/sfondo-scatole-grigio-opt.png) no-repeat bottom center;
  p {
    padding: 5rem;
    max-width: 900px;
    text-align:center;
    margin: 0 auto;
    color:$white!important;
    font-size:1.5rem!important;
    strong {
      font-family:$font-family-aglet!important;
    }
  }
}

.chi-siamo-date {
  padding: 2rem 3rem;
  background-color:grey;
  .elementor-element-populated {
    padding:10px!important;
  }
  .elementor-widget-wrap {
    border-radius:.25rem;
    background-color:white;
    padding: 2rem 3rem;
    font-family:$font-family-aglet;
    p {
      font-family: $font-family-aglet!important;
      margin-top:2rem;
      font-size: 1.0rem!important;
    }
    h6 {
      font-weight:300;
      font-size:1.3rem;
    }
    h5 {
      font-size:2rem;
      font-weight:300;
      &:after {
        content: '';
        border-bottom:5px solid $green;
        position: absolute;
        left: -3rem;
        top: 130%;
        z-index: 1;
        width: 220px;
      }
    }
    .chi-siamo-date-nome {
      p {
        font-family:$font-family-aglet;
      }
    }
    .chi-siamo-date-anno {
    }
  }
}

//not found page
.not-found-list {
  li {
    font-weight:600;
    &:before {
      font-family: 'Material Icons';
      content: "\e876";
      color:$green;
      vertical-align:middle;
      padding-right:0.5rem;
    }
  }
  ul {
    padding:5px 0px 5px 20px;
  }
  a {
    color:$midgrey;
  }
  a:hover {
    font-weight:bold;
  }
}


@media (min-width: 992px){
  .customer-main {
    .main-card {
      padding:2rem;
    }
    .page-content { form {
      .label, label {
        float:left;
      }
      max-width: 550px;
      margin: unset;
    }
   }
  }
}

@media (max-width: 1200px) and (min-width: 769px) {
  .elementor-flip-box-overlay {
    padding:10px;
  }
  .box-links-casi .elementor-flip-box-content h3 { top: 20%; }
  .box-links-casi .elementor-flip-box-content .elementor-flip-box-description { font-size: 0.8rem; }
}

@media (max-width: 1100px){


  .chi-siamo-date {
    h5 {
      &:after {
        width: 190px;
      }
    }
  }
}

@media (max-width: 990px) and (min-width: 769px) {
  .box-links-casi .elementor-flip-box-content h3 { top: 15%; }
  .box-links-casi .elementor-flip-box-content h3:after { bottom: -15px; }
}

@media (max-width: 768px){
  .box-links-casi .elementor-flip-box-content h3 { top: 35%; }
  .box-links-casi .elementor-flip-box-content .elementor-flip-box-description { bottom: 35%; }
}

@media (max-width:590px){
  // chi siamo
  .bg-grey-scatole  {
    p {
      padding: 2rem;
      font-size:1rem!important;
    }
  }

}

@media (max-width:380px){

  .elementor-flip-box-content {
    h3 {
      top:20px!important;
    }
    .elementor-flip-box-description {
      bottom:20px!important;
    }
   }


}

.identification-number-container {
  max-width: 350px;
  @media (max-width: 990px) {
    max-width: 100%;
  }
  .info-message {
    font-size: 70%;
    margin-top: 5px;
  }
}

.page-wrapper--module-b2bregistration-business {
  .custom-checkout-info-message {
    max-width: 724px;
    margin: 15px auto;
  }
}

.page-wrapper--stores {
  .filter-section {
    margin: 20px 0;
    text-align: right;
    select {
      height: 40px;
      padding: 0 10px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
}
.page-stores {
  display: flex;
  flex-wrap: wrap;
  article {
    flex-grow: 1;
    max-width: 33%;
    width: 33%;
    &.hidden-store {
      display: none;
    }
    @media screen and (max-width: 990px) {
      max-width: 50%;
      width: 50%;
    }
    @media screen and (max-width: 480px) {
      max-width: 100%;
      width: 100%;
    }
  }
  #content-wrapper {
    margin-top: 50px;
  }

  .row-store {
    font-size: 15px;
    address {
      margin-bottom: 0;
    }
    .store-description, ul, table {
      max-width: 350px;
      width: 100%;
    }
  }

  .store-item-container {
    .table-sm th, .table-sm td {
      padding: 0 0.5px;
    }
    .table-borderless {
      ul {
        margin-bottom: 0;
      }
    }
  }

  .map.leaflet-container {
    margin-bottom: 80px;
    .leaflet-control-dialog-contents .btn-info {
      background-color: #4D4E4C;
      color: #C9DB57;
    }
    .text-white {
      color: #4D4E4C !important;
    }
    .leaflet-control-dialog-contents h4 {
      font-size: 15px !important;
    }
  }
}

.stores-foreign-message {
  color: #7a7a7a;
  h2 {
    margin-bottom: 20px;
  }
}

.section-agent h3 {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 24px;
}

.section-agent .agent-location {
  font-size: 16px;
  margin-bottom: 20px;
}

.section-agent .agent-contact .block {
  margin-bottom: 10px;
  font-size: 16px;
}

.section-agent .agent-contact .block .icon {
  margin-right: 10px;
}

.content-link-agent-italy {
  margin-bottom: 20px;
  font-weight: 600;
}

.content-link-agent-italy a {
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 20px;
}

.page-agenti #italia {
  margin: 40px 0;
}
.page-agenti select {
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid #D1D1D1;
  padding: 10px;
}

.page-agenti .label-select {
  font-size: 1rem;
}

.page-agenti .agente-contact {
  padding-left: 30px;
  border-left: 10px solid #DBDBDB;
}

.page-agenti .agente-contact .icon {
  margin-right: 10px;
}
.page-agenti .agente-contact .name {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 24px;
}

.page-agenti .agente-contact .block {
  margin-bottom: 10px;
  font-size: 16px;
}
